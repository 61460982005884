import React from "react";

const IcTitleIcon = () => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line y1="1" x2="17" y2="1" stroke="#3B3F44" strokeWidth="2" />
      <line x1="16" y1="1" x2="16" y2="18" stroke="#3B3F44" strokeWidth="2" />
    </svg>
  );
};

export default React.memo(IcTitleIcon);
