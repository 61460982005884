import styled from "styled-components";

const Footer = () => {
  return (
    <StyledWrap>
      <StyledContact>
        <dt>Contact Us</dt>
        <dd>
          <a href="mailto:bpa@bpa.kr">bpa@bpa.kr</a>
        </dd>
      </StyledContact>

      <StyledAddress>
        <dt>ADDRESS</dt>
        <dd>
          Office 서울특별시 성동구 아차산로 126, 502호(성수동2가, 더리브
          세종타워)
        </dd>
      </StyledAddress>

      <StyledName>© 사단법인 브랜드지식재산권보호협회</StyledName>
    </StyledWrap>
  );
};

const StyledWrap = styled.footer`
  position: relative;
  max-width: 1920px;
  min-width: 1508px;
  margin: 0 auto;
  height: 214px;
  box-sizing: border-box;
  background: #202124;

  @media only screen and (max-width: 768px) {
    max-width: 100%;
    min-width: 100%;
    height: auto;
    padding: 40px 18px;
  }
`;

const StyledContact = styled.dl`
  position: absolute;
  left: 52px;
  top: 38px;
  text-align: left;

  > dt {
    font-size: 16px;
    color: #646d74;

    @media only screen and (max-width: 768px) {
      font-size: 14px;
    }
  }

  > dd {
    padding-top: 3px;
    font-size: 18px;
    color: #95a1ac;

    @media only screen and (max-width: 768px) {
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 768px) {
    position: initial;
  }
`;

const StyledAddress = styled.address`
  padding: 38px 0 0 668px;
  font-style: normal;

  > dt {
    text-align: left;
    font-size: 16px;
    color: #646d74;

    @media only screen and (max-width: 768px) {
      font-size: 14px;
    }
  }

  > dd {
    padding-top: 3px;
    text-align: left;
    font-size: 18px;
    color: #95a1ac;

    @media only screen and (max-width: 768px) {
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 27px 0 26px;
  }
`;

const StyledName = styled.p`
  position: absolute;
  right: 41px;
  top: 38px;
  font-size: 16px;
  color: #95a1ac;
  text-align: left;

  @media only screen and (max-width: 768px) {
    position: initial;
    font-size: 14px;
  }
`;

export default Footer;
