import styled from "styled-components";

const Gnb = () => (
  <StyledWrap>
    <StyledList>
      <StyledListItem>
        <span>협회소개</span>
        <StyledMenu>
          <StyledMenuItem href="/introduce">설립 취지</StyledMenuItem>
          <StyledMenuItem href="/introduce#executives">
            회장 및 임원사
          </StyledMenuItem>
          <StyledMenuItem href="/introduce#organizationLocation">
            조직도
          </StyledMenuItem>
          <StyledMenuItem href="/introduce#organizationLocation">
            오시는 길
          </StyledMenuItem>
        </StyledMenu>
      </StyledListItem>
      <StyledListItem>
        <span>주요사업</span>
        <StyledMenuType2>
          <StyledMenuItem href="/business">
            회원사 지식재산권 보호 모니터링∙단속 지원
          </StyledMenuItem>
          <StyledMenuItem href="/business#businessArea2">
            위조상품 유통방지 협의회
          </StyledMenuItem>
          <StyledMenuItem href="/business#businessArea3">
            회원사 법률 지원
          </StyledMenuItem>
          <StyledMenuItem href="/business#businessArea4">
            회원사 브랜드 투자 지원
          </StyledMenuItem>
          <StyledMenuItem href="/business#businessArea5">
            회원사 지식재산권 보호 교육 지원
          </StyledMenuItem>
        </StyledMenuType2>
      </StyledListItem>
      <StyledListItem>
        <span>소식</span>
        <StyledMenuType3>
          <StyledMenuItem href="/notice">공지사항</StyledMenuItem>
          <StyledMenuItem href="/press">보도자료</StyledMenuItem>
          <StyledMenuItem href="/news">지식재산권 뉴스</StyledMenuItem>
        </StyledMenuType3>
      </StyledListItem>
      <StyledListItem>
        <span>함께하는 곳</span>
        <StyledMenu>
          <StyledMenuItem href="/memberList">회원사 리스트</StyledMenuItem>
          <StyledMenuItem href="/memberNews">회원사 동향</StyledMenuItem>
          <StyledMenuItem href="file/guide.zip" download>
            협회 가입안내
          </StyledMenuItem>
        </StyledMenu>
      </StyledListItem>
    </StyledList>
  </StyledWrap>
);

const StyledWrap = styled.nav`
  width: 100%;
  height: 100px;
  position: relative;
  z-index: 100;
`;

const StyledList = styled.ul`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 35px;
  box-sizing: border-box;
`;

const StyledListItem = styled.li`
  position: relative;
  padding-bottom: 6px;
  font-size: 20px;
  color: #95a1ac;
  cursor: pointer;

  & + & {
    margin-left: 53px;
  }

  &:hover {
    & > div {
      visibility: visible;
      opacity: 1;
      transition: all 0.6s;
    }
  }
`;

const StyledMenu = styled.div`
  position: absolute;
  left: 50%;
  top: 36px;
  padding: 20px;
  margin-left: -76px;
  width: 152px;
  box-sizing: border-box;
  background-color: #202124;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
`;

const StyledMenuType2 = styled.div`
  position: absolute;
  left: 50%;
  top: 36px;
  padding: 20px;
  margin-left: -117px;
  width: 320px;
  box-sizing: border-box;
  background-color: #202124;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
`;

const StyledMenuType3 = styled.div`
  position: absolute;
  left: 50%;
  top: 36px;
  padding: 20px;
  margin-left: -86px;
  width: 173px;
  box-sizing: border-box;
  background-color: #202124;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
`;

const StyledMenuItem = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  color: #aab4bc;
  font-size: 16px;

  &:hover {
    color: #fff;
  }

  & + & {
    border-top: 1px solid #3b3f44;
  }

  > svg {
    margin-left: 8px;
  }
`;

export default Gnb;
