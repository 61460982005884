import React from "react";

const IcLogoNew = () => {
  return (
    <svg
      width="350"
      height="43"
      viewBox="0 0 350 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2567_29911)">
        <path d="M149.9 0.8H146.9V24.7H149.9V0.8Z" fill="black" />
        <path d="M224.1 21.6H208V16.2H205.1V24.2H224.1V21.6Z" fill="black" />
        <path d="M75.8 20.3H53.8V22.9H75.8V20.3Z" fill="black" />
        <path
          d="M200.5 24.7V0.8H197.6V10.7H194.9V0.8H192.1V24.6H194.9V13.5H197.6V24.7H200.5Z"
          fill="black"
        />
        <path
          d="M143.1 4.9V2.3H128.7V4.9H134.9L128.3 20.7L131 21.8L136 9.4L141 21.6L143.6 20.5L137 4.9H143.1Z"
          fill="black"
        />
        <path
          d="M188.5 21.6L191 20.9L185.7 4.9H190.5V2.3H178.8V4.9H183.8L178.4 21L181 21.8L184.8 10.2L188.5 21.6Z"
          fill="black"
        />
        <path
          d="M90 12.2H82.1V9.3H89.7V1.5H79.3V4H86.9V6.9H79.3V14.8H90V12.2Z"
          fill="black"
        />
        <path d="M239.9 10H242.9V1.3H230.3V3.9H239.9V10Z" fill="black" />
        <path
          d="M309.3 15C310 15.3 310.7 15.4 311.4 15.4C312.1 15.4 312.9 15.3 313.5 15C314.2 14.7 314.7 14.4 315.2 13.9C315.7 13.4 316.1 12.9 316.4 12.3C316.7 11.7 316.8 11.1 316.8 10.3C316.8 9.5 316.7 9 316.4 8.3C316.1 7.7 315.7 7.2 315.2 6.7C314.7 6.2 314.1 5.9 313.5 5.6C312.8 5.3 312.1 5.2 311.4 5.2C310.7 5.2 309.9 5.3 309.3 5.6C308.6 5.9 308.1 6.2 307.6 6.7C307.1 7.2 306.7 7.7 306.4 8.3C306.1 8.9 306 9.6 306 10.3C306 11 306.1 11.6 306.4 12.3C306.7 12.9 307.1 13.4 307.6 13.9C308.1 14.4 308.7 14.7 309.3 15ZM309.6 8.5C310.1 8 310.7 7.7 311.4 7.7C312.1 7.7 312.7 8 313.2 8.5C313.7 9 314 9.6 314 10.3C314 11 313.7 11.6 313.2 12.1C312.7 12.6 312.1 12.9 311.4 12.9C310.7 12.9 310.1 12.6 309.6 12.1C309.1 11.6 308.8 11 308.8 10.3C308.8 9.6 309.1 9 309.6 8.5Z"
          fill="black"
        />
        <path
          d="M74 1.8H71.1V5.6H58.5V1.8H55.6V15.7H74V1.8ZM71.1 13.1H58.5V8.2H71.1V13.1Z"
          fill="black"
        />
        <path
          d="M291.7 20.3V17.3C292 17.3 292.3 17.1 292.6 17C293.3 16.7 294 16.3 294.5 15.8C295 15.3 295.5 14.7 295.8 14C296.1 13.3 296.3 12.6 296.3 11.9C296.3 11.2 296.1 10.4 295.8 9.7C295.5 9 295 8.4 294.5 7.9C294 7.4 293.3 7 292.6 6.7C291.9 6.4 291.1 6.3 290.3 6.3C289.5 6.3 288.7 6.4 288 6.7C287.3 7 286.6 7.4 286.1 7.9C285.6 8.4 285.1 9 284.8 9.7C284.5 10.4 284.3 11.1 284.3 11.9C284.3 12.7 284.5 13.4 284.8 14C285.1 14.7 285.6 15.2 286.1 15.8C286.6 16.3 287.3 16.7 288 17C288.3 17.1 288.5 17.2 288.8 17.3V20.3H279.3V22.9H301.3V20.3H291.7ZM288.1 14.1C287.5 13.5 287.2 12.8 287.2 11.9C287.2 11 287.5 10.3 288.1 9.7C288.7 9.1 289.4 8.8 290.3 8.8C291.2 8.8 291.9 9.1 292.5 9.7C293.1 10.3 293.4 11 293.4 11.9C293.4 12.8 293.1 13.5 292.5 14.1C291.9 14.7 291.2 15 290.3 15C289.4 15 288.7 14.7 288.1 14.1Z"
          fill="black"
        />
        <path
          d="M235.6 18.3H238.6V14.2H245V11.5H229.1V14.2H235.6V18.3Z"
          fill="black"
        />
        <path
          d="M246.8 18.5H249.8V0.8H246.8V6.4H244.1V9H246.8V18.5Z"
          fill="black"
        />
        <path
          d="M276.2 20.3H266.7V15.5H274.4V1.8H271.5V5.5H258.9V1.8H256V15.5H263.7V20.3H254.2V22.9H276.2V20.3ZM258.9 12.9V8.1H271.5V12.9H258.9Z"
          fill="black"
        />
        <path
          d="M299.7 3.2H291.8V0.6H288.6V3.2H280.8V5.8H299.7V3.2Z"
          fill="black"
        />
        <path d="M350 0.8H347V24.7H350V0.8Z" fill="black" />
        <path
          d="M344.3 3.2H338.7V0.6H335.5V3.2H329.8V5.7H344.3V3.2Z"
          fill="black"
        />
        <path
          d="M345.1 19.8H338.5V16.8C338.7 16.8 339 16.7 339.3 16.6C340 16.3 340.6 15.9 341.2 15.4C341.7 14.9 342.1 14.3 342.4 13.7C342.7 13 342.9 12.3 342.9 11.6C342.9 10.9 342.7 10.2 342.4 9.5C342.1 8.8 341.7 8.3 341.2 7.8C340.7 7.3 340 6.9 339.3 6.6C338.6 6.3 337.8 6.2 337 6.2C336.2 6.2 335.4 6.3 334.7 6.6C334 6.9 333.4 7.3 332.8 7.8C332.3 8.3 331.9 8.9 331.6 9.5C331.3 10.2 331.1 10.9 331.1 11.6C331.1 12.3 331.3 13 331.6 13.7C331.9 14.4 332.3 14.9 332.8 15.4C333.3 15.9 334 16.3 334.7 16.6C335 16.7 335.3 16.8 335.5 16.8V19.8H329.2V22.4H345.1V19.8ZM335 13.5C334.4 12.9 334.1 12.3 334.1 11.5C334.1 10.7 334.4 10 335 9.5C335.6 9 336.3 8.6 337.1 8.6C337.9 8.6 338.6 8.9 339.2 9.5C339.8 10.1 340.1 10.7 340.1 11.5C340.1 12.3 339.8 13 339.2 13.5C338.6 14.1 337.9 14.4 337.1 14.4C336.3 14.4 335.6 14.1 335 13.5Z"
          fill="black"
        />
        <path
          d="M322.3 14.7H325.3V0.8H322.3V5H318.4V7.5H322.3V9.9H318.4V12.4H322.3V14.7Z"
          fill="black"
        />
        <path
          d="M325.3 15.6H322.4V17.4H309.5V15.6H306.6V24.2H325.3V15.6ZM322.3 21.9H309.4V19.6H322.3V21.9Z"
          fill="black"
        />
        <path
          d="M318.3 2.3H313V0.3H309.8V2.3H304.4V4.8H318.3V2.3Z"
          fill="black"
        />
        <path
          d="M161.2 5.8L166.3 13.7L168.7 12.1L161.2 0.7L153.6 12.3L156.1 13.9L161.2 5.8Z"
          fill="black"
        />
        <path d="M175 24.5V15.6H155.9V18.3H172V24.5H175Z" fill="black" />
        <path d="M250.1 21.7H233.6V16.4H230.7V24.3H250.1V21.7Z" fill="black" />
        <path d="M125.9 20.3H103.9V22.9H125.9V20.3Z" fill="black" />
        <path
          d="M124.2 12.2H108.6V4.6H124.1V2H105.7V14.8H124.2V12.2Z"
          fill="black"
        />
        <path d="M175 0.8H172V14.2H175V0.8Z" fill="black" />
        <path
          d="M223.5 9.4H226.9V6.4H223.5V0.8H220.5V16.1H223.5V9.4Z"
          fill="black"
        />
        <path
          d="M94.8 9.2H97.2V16.4H100.1V0.8H97.2V6.5H94.8V0.8H92V16.3H94.8V9.2Z"
          fill="black"
        />
        <path
          d="M210.6 5.8L215.6 14.3L218 12.9L210.6 0.7L203.3 13.1L205.7 14.5L210.6 5.8Z"
          fill="black"
        />
        <path d="M80.3 16.9V24.2H100.3V21.6H83.3V16.9H80.3Z" fill="black" />
        <path
          d="M64.7 35.1C64.4 35.3 64.2 35.5 64 35.7V34.9H62.8V40.5H64.1V37.9C64.1 37.2 64.2 36.8 64.5 36.4C64.8 36.1 65.2 35.9 65.7 35.9H65.8V34.8C65.4 34.8 65 34.8 64.7 35.1Z"
          fill="black"
        />
        <path
          d="M83.9 35.6C83.4 35.1 82.9 34.8 82.2 34.8C81.5 34.8 80.7 35.1 80.2 35.7C79.7 36.3 79.4 37 79.4 37.7C79.4 38.4 79.7 39.2 80.2 39.7C80.7 40.3 81.4 40.6 82.2 40.6C83 40.6 83.5 40.3 84 39.8V40.5H85.2V32.2H83.9V35.6ZM83.9 38.1C83.9 38.5 83.7 38.9 83.4 39.1C83.1 39.4 82.7 39.6 82.3 39.6C81.9 39.6 81.4 39.4 81.1 39.1C80.8 38.7 80.7 38.3 80.7 37.8C80.7 37.3 80.8 36.8 81.1 36.5C81.4 36.1 81.8 36 82.3 36C82.8 36 83.1 36.2 83.4 36.5C83.7 36.8 83.8 37.1 83.9 37.5C83.9 37.7 83.9 38 83.9 38.2V38.1Z"
          fill="black"
        />
        <path
          d="M76.1 34.8C75.8 34.8 75.5 34.8 75.2 35C74.9 35.2 74.7 35.4 74.6 35.6V34.9H73.4V40.5H74.7V37.7C74.7 37.2 74.7 36.9 74.9 36.6C75 36.4 75.2 36.2 75.4 36C75.6 35.9 75.8 35.8 76 35.8C76.4 35.8 76.7 35.9 76.9 36.2C77.1 36.5 77.2 36.9 77.2 37.5V40.4H78.5V37.2C78.5 36.5 78.3 35.9 77.9 35.4C77.5 35 77 34.7 76.3 34.7L76.1 34.8Z"
          fill="black"
        />
        <path
          d="M60.4 36.4C60.6 36.4 60.8 36.2 61 35.9C61.3 35.6 61.4 35.2 61.4 34.8C61.4 34.4 61.3 33.9 61 33.6C60.8 33.3 60.4 33 60 32.9C59.6 32.8 59.1 32.7 58.6 32.7H55.7V40.4H58.7C59.6 40.4 60.3 40.2 60.7 39.9C61 39.7 61.2 39.5 61.3 39.2C61.5 38.9 61.6 38.5 61.6 38.2C61.6 37.9 61.5 37.4 61.2 37C60.9 36.7 60.6 36.5 60.3 36.3L60.4 36.4ZM57 33.9H58.5C59.1 33.9 59.5 33.9 59.7 34.2C59.9 34.4 60.1 34.6 60.1 35C60.1 35.4 60 35.6 59.7 35.8C59.5 36 59.1 36.1 58.5 36.1H57V33.9ZM59.8 39.3C59.6 39.4 59.2 39.5 58.6 39.5H57.1V37.1H58.6C59.2 37.1 59.6 37.1 59.8 37.3C60.2 37.5 60.4 37.9 60.4 38.3C60.4 38.7 60.2 39.1 59.8 39.3Z"
          fill="black"
        />
        <path
          d="M71 35.4C70.5 35 69.8 34.9 68.9 34.9C68.2 34.9 67.6 35 67 35.3L67.3 36.3C67.9 36.1 68.4 35.9 68.9 35.9C69.4 35.9 69.8 35.9 70.1 36.2C70.4 36.4 70.5 36.7 70.5 37H69.2C68.3 37 67.7 37.2 67.2 37.5C66.7 37.9 66.4 38.3 66.4 38.9C66.4 39.5 66.6 39.8 67 40.2C67.4 40.6 67.9 40.7 68.6 40.7C69.3 40.7 69.3 40.7 69.7 40.5C70 40.4 70.3 40.2 70.6 40V40.6H71.8V37.2C71.8 36.4 71.5 35.8 71.1 35.5L71 35.4ZM70.6 38.9C70.5 39.1 70.2 39.3 69.9 39.5C69.6 39.7 69.3 39.7 68.9 39.7C68.5 39.7 68.2 39.7 68 39.5C67.8 39.3 67.7 39.1 67.7 38.9C67.7 38.7 67.8 38.4 68.1 38.2C68.3 38 68.8 38 69.4 38H70.5V38.9H70.6Z"
          fill="black"
        />
        <path
          d="M101.3 39.4C101.2 39.4 101.1 39.1 101.1 38.8V36H102.3V35H101.1V33.2H99.8V35H98.8V36H99.8V39C99.8 39.5 99.9 39.9 100.2 40.2C100.5 40.5 100.9 40.6 101.5 40.6H102.3V39.6H101.8C101.6 39.6 101.4 39.6 101.3 39.5V39.4Z"
          fill="black"
        />
        <path
          d="M95.8 34.8C95.5 34.8 95.2 34.8 94.9 35C94.6 35.2 94.4 35.4 94.3 35.6V34.9H93.1V40.5H94.4V37.7C94.4 37.2 94.4 36.9 94.6 36.6C94.7 36.4 94.9 36.2 95.1 36C95.3 35.9 95.5 35.8 95.7 35.8C96.1 35.8 96.4 35.9 96.6 36.2C96.8 36.5 96.9 36.9 96.9 37.5V40.4H98.2V37.2C98.2 36.5 98 35.9 97.6 35.4C97.2 35 96.7 34.7 96 34.7L95.8 34.8Z"
          fill="black"
        />
        <path d="M91.4 32.8H90.1V40.5H91.4V32.8Z" fill="black" />
        <path d="M146.4 32.3H145.1V40.6H146.4V32.3Z" fill="black" />
        <path
          d="M129.8 33.2H128.5V35H127.5V36H128.5V39C128.5 39.5 128.6 39.9 128.9 40.2C129.2 40.5 129.6 40.6 130.2 40.6H131V39.6H130.5C130.3 39.6 130.1 39.6 130 39.5C129.9 39.5 129.8 39.2 129.8 38.9V36.1H131V35.1H129.8V33.2Z"
          fill="black"
        />
        <path
          d="M135.8 37.8C135.8 38.3 135.8 38.6 135.6 38.9C135.5 39.1 135.3 39.3 135.1 39.5C134.9 39.6 134.7 39.7 134.5 39.7C134.1 39.7 133.8 39.6 133.6 39.3C133.4 39 133.3 38.6 133.3 38V35.1H132V38.3C132 39 132.2 39.7 132.6 40.1C133 40.5 133.5 40.8 134.2 40.8C134.9 40.8 134.8 40.8 135.1 40.5C135.4 40.4 135.6 40.1 135.7 39.9V40.6H136.9V35H135.6V37.8H135.8Z"
          fill="black"
        />
        <path
          d="M124.8 39.6C124.3 39.6 123.8 39.4 123.4 39.1C123 38.8 122.9 38.3 122.9 37.8C122.9 37.3 123.1 36.8 123.4 36.5C123.8 36.1 124.2 36 124.8 36C125.4 36 125.9 36.2 126.4 36.6L126.8 35.6C126.2 35.1 125.5 34.9 124.8 34.9C124.1 34.9 123.1 35.2 122.5 35.8C121.9 36.4 121.6 37.1 121.6 37.9C121.6 38.7 121.9 39.4 122.5 40C123.1 40.6 123.9 40.9 124.8 40.9C125.7 40.9 126.3 40.7 126.9 40.2L126.5 39.2C125.9 39.6 125.4 39.8 124.8 39.8V39.6Z"
          fill="black"
        />
        <path
          d="M142.8 35.4C142.3 35 141.6 34.9 140.7 34.9C140 34.9 139.3 35 138.8 35.3L139.1 36.3C139.7 36.1 140.2 35.9 140.7 35.9C141.2 35.9 141.6 35.9 141.9 36.2C142.2 36.4 142.3 36.7 142.3 37H141C140.1 37 139.5 37.2 139 37.5C138.5 37.9 138.2 38.3 138.2 38.9C138.2 39.5 138.4 39.8 138.8 40.2C139.2 40.6 139.7 40.7 140.4 40.7C141.1 40.7 141.1 40.7 141.5 40.5C141.8 40.4 142.1 40.2 142.4 40V40.6H143.6V37.2C143.6 36.4 143.4 35.8 142.9 35.5L142.8 35.4ZM142.3 38.9C142.2 39.1 141.9 39.3 141.6 39.5C141.3 39.7 141 39.7 140.6 39.7C140.2 39.7 139.9 39.7 139.7 39.5C139.5 39.3 139.4 39.1 139.4 38.9C139.4 38.7 139.5 38.4 139.8 38.2C140 38 140.5 38 141.1 38H142.2V38.9H142.3Z"
          fill="black"
        />
        <path
          d="M105.9 34.8C105.1 34.8 104.4 35.1 103.9 35.6C103.4 36.1 103.1 36.9 103.1 37.7C103.1 38.5 103.4 39.3 104 39.8C104.6 40.3 105.4 40.6 106.3 40.6C107.2 40.6 107.8 40.3 108.4 39.8L108 38.9C107.5 39.4 106.9 39.6 106.3 39.6C105.7 39.6 105.3 39.4 104.9 39.1C104.7 38.9 104.5 38.6 104.4 38.3H108.7C108.7 38.1 108.7 37.9 108.7 37.7C108.7 36.9 108.4 36.2 107.9 35.7C107.4 35.2 106.7 34.9 105.9 34.9V34.8ZM104.3 37.3C104.3 36.9 104.5 36.6 104.7 36.3C105 36 105.4 35.8 105.8 35.8C106.2 35.8 106.6 36 106.9 36.2C107.2 36.5 107.3 36.8 107.4 37.2H104.3V37.3Z"
          fill="black"
        />
        <path d="M111.2 32.3H109.9V40.6H111.2V32.3Z" fill="black" />
        <path
          d="M118 34.8C117.2 34.8 116.5 35.1 116 35.6C115.5 36.1 115.2 36.9 115.2 37.7C115.2 38.5 115.5 39.3 116.1 39.8C116.7 40.3 117.5 40.6 118.4 40.6C119.3 40.6 119.9 40.3 120.5 39.8L120.1 38.9C119.6 39.4 119 39.6 118.4 39.6C117.8 39.6 117.3 39.4 117 39.1C116.8 38.9 116.6 38.6 116.5 38.3H120.8C120.8 38.1 120.8 37.9 120.8 37.7C120.8 36.9 120.5 36.2 120 35.7C119.5 35.2 118.8 34.9 118 34.9V34.8ZM116.5 37.3C116.5 36.9 116.7 36.6 116.9 36.3C117.2 36 117.6 35.8 118 35.8C118.4 35.8 118.8 36 119.1 36.2C119.4 36.5 119.5 36.8 119.6 37.2H116.5V37.3Z"
          fill="black"
        />
        <path d="M114 32.3H112.7V40.6H114V32.3Z" fill="black" />
        <path
          d="M183.6 35.2C183.3 35.4 183.1 35.6 182.9 35.8V35H181.7V40.6H183V38C183 37.3 183.1 36.9 183.4 36.5C183.7 36.2 184.1 36 184.6 36H184.7V34.9C184.3 34.9 183.9 34.9 183.6 35.2Z"
          fill="black"
        />
        <path
          d="M187.8 33.2H186.5V35H185.5V36H186.5V39C186.5 39.5 186.6 39.9 186.9 40.2C187.2 40.5 187.6 40.6 188.2 40.6H189V39.6H188.5C188.3 39.6 188.1 39.6 188 39.5C187.9 39.5 187.8 39.2 187.8 38.9V36.1H189V35.1H187.8V33.2Z"
          fill="black"
        />
        <path
          d="M192.4 38.8L190.9 35H189.6L191.8 40.3L190.8 42.7H192.1L195.2 35H193.9L192.4 38.8Z"
          fill="black"
        />
        <path
          d="M177.7 34.8C176.9 34.8 176.2 35.1 175.7 35.6C175.2 36.1 174.9 36.8 174.9 37.7C174.9 38.6 175.2 39.3 175.8 39.8C176.4 40.3 177.2 40.6 178.1 40.6C179 40.6 179.6 40.3 180.2 39.8L179.8 38.9C179.3 39.4 178.7 39.6 178.1 39.6C177.5 39.6 177 39.4 176.7 39.1C176.5 38.9 176.3 38.6 176.2 38.3H180.5C180.5 38.1 180.5 37.9 180.5 37.7C180.5 36.9 180.2 36.2 179.7 35.7C179.2 35.2 178.5 34.9 177.7 34.9V34.8ZM176.2 37.3C176.2 36.9 176.4 36.6 176.6 36.3C176.9 36 177.3 35.8 177.7 35.8C178.1 35.8 178.5 36 178.8 36.2C179.1 36.5 179.2 36.8 179.3 37.2H176.2V37.3Z"
          fill="black"
        />
        <path
          d="M171.4 34.8C170.7 34.8 170.1 35.1 169.6 35.6V34.9H168.4V42.6H169.7V39.8C170.2 40.3 170.7 40.6 171.4 40.6C172.1 40.6 172.9 40.3 173.4 39.7C173.9 39.1 174.2 38.4 174.2 37.7C174.2 37 173.9 36.2 173.4 35.6C172.9 35 172.2 34.7 171.4 34.7V34.8ZM172.4 39.1C172.1 39.5 171.7 39.6 171.2 39.6C170.7 39.6 170.4 39.4 170.1 39.1C169.8 38.8 169.7 38.5 169.6 38.1C169.6 37.9 169.6 37.6 169.6 37.4C169.6 37 169.8 36.6 170.1 36.3C170.4 36 170.8 35.8 171.2 35.8C171.6 35.8 172.1 36 172.4 36.3C172.7 36.7 172.8 37.1 172.8 37.6C172.8 38.1 172.7 38.6 172.4 38.9V39.1Z"
          fill="black"
        />
        <path
          d="M155.8 33.5C155.3 33 154.6 32.8 153.6 32.8H151.1V40.5H152.4V37.7H153.6C154.5 37.7 155.2 37.5 155.8 37C156.3 36.5 156.6 35.9 156.6 35.2C156.6 34.5 156.3 33.9 155.8 33.4V33.5ZM154.8 36.2C154.5 36.4 154 36.6 153.4 36.6H152.5V33.8H153.4C154.1 33.8 154.5 33.9 154.8 34.2C155.1 34.4 155.2 34.8 155.2 35.2C155.2 35.6 155.1 36 154.8 36.2Z"
          fill="black"
        />
        <path
          d="M164.3 34.8C163.5 34.8 162.8 35.1 162.2 35.6C161.6 36.2 161.3 36.9 161.3 37.7C161.3 38.5 161.6 39.2 162.2 39.8C162.8 40.4 163.5 40.6 164.3 40.6C165.1 40.6 165.8 40.3 166.4 39.8C167 39.2 167.3 38.6 167.3 37.7C167.3 36.8 167 36.2 166.4 35.6C165.8 35 165.1 34.8 164.3 34.8ZM165.5 39.1C165.2 39.5 164.8 39.6 164.3 39.6C163.8 39.6 163.5 39.4 163.1 39.1C162.8 38.7 162.6 38.3 162.6 37.8C162.6 37.3 162.8 36.8 163.1 36.5C163.4 36.1 163.8 36 164.3 36C164.8 36 165.2 36.2 165.5 36.5C165.8 36.9 166 37.3 166 37.8C166 38.3 165.8 38.8 165.5 39.1Z"
          fill="black"
        />
        <path
          d="M159.7 35.2C159.4 35.4 159.2 35.6 159 35.8V35H157.8V40.6H159.1V38C159.1 37.3 159.2 36.9 159.5 36.5C159.8 36.2 160.2 36 160.7 36H160.8V34.9C160.4 34.9 160 34.9 159.7 35.2Z"
          fill="black"
        />
        <path
          d="M300.9 37.3C300.7 37.3 300.6 37.3 300.5 37.2C300.2 37.1 299.9 37 299.7 36.9C299.5 36.8 299.4 36.7 299.4 36.5C299.4 36.3 299.4 36.2 299.6 36.1C299.8 36 299.9 35.9 300.2 35.9C300.6 35.9 300.9 36 301.1 36.2C301.2 36.3 301.4 36.5 301.5 36.6L302.3 35.9C302.2 35.7 302 35.5 301.8 35.3C301.4 35 300.9 34.9 300.3 34.9C299.7 34.9 299.2 35.1 298.8 35.4C298.4 35.7 298.2 36.1 298.2 36.6C298.2 37.1 298.3 37.2 298.5 37.4C298.7 37.6 299 37.8 299.4 38C299.7 38.1 300 38.2 300.3 38.3C300.6 38.3 300.8 38.5 301 38.6C301.2 38.7 301.3 38.9 301.3 39C301.3 39.1 301.3 39.3 301 39.5C300.8 39.6 300.6 39.7 300.3 39.7C300 39.7 299.7 39.7 299.5 39.5C299.2 39.3 299 39.1 298.8 38.8L298 39.6C298.2 39.9 298.5 40.2 298.8 40.4C299.2 40.7 299.8 40.8 300.3 40.8C300.8 40.8 301.5 40.6 302 40.3C302.4 40 302.7 39.6 302.7 39.1C302.7 38.6 302.6 38.4 302.3 38.1C302 37.8 301.6 37.6 301.1 37.4L300.9 37.3Z"
          fill="black"
        />
        <path
          d="M284.5 34.8C284.2 34.8 283.9 34.8 283.6 35C283.3 35.2 283.1 35.4 283 35.6V34.9H281.8V40.5H283.1V37.7C283.1 37.2 283.1 36.9 283.3 36.6C283.4 36.4 283.6 36.2 283.8 36C284 35.9 284.2 35.8 284.4 35.8C284.8 35.8 285.1 35.9 285.3 36.2C285.5 36.5 285.6 36.9 285.6 37.5V40.4H286.9V37.2C286.9 36.5 286.7 35.9 286.3 35.4C285.9 35 285.4 34.7 284.7 34.7L284.5 34.8Z"
          fill="black"
        />
        <path
          d="M272.8 32.4C272.6 32.4 272.4 32.4 272.2 32.6C272 32.8 272 33 272 33.2C272 33.4 272 33.6 272.2 33.8C272.4 34 272.6 34 272.8 34C273 34 273.2 34 273.4 33.8C273.6 33.6 273.6 33.5 273.6 33.2C273.6 32.9 273.6 32.8 273.4 32.6C273.2 32.4 273.1 32.4 272.8 32.4Z"
          fill="black"
        />
        <path
          d="M293.1 32.8L290.4 40.5H291.7L292.5 38.2H295.5L296.3 40.5H297.6L294.9 32.8H293.1ZM292.9 37.2L294 33.9L295.1 37.2H292.9Z"
          fill="black"
        />
        <path
          d="M277.6 34.8C276.8 34.8 276.1 35.1 275.5 35.6C274.9 36.2 274.6 36.9 274.6 37.7C274.6 38.5 274.9 39.2 275.5 39.8C276.1 40.4 276.8 40.6 277.6 40.6C278.4 40.6 279.1 40.3 279.7 39.8C280.3 39.2 280.6 38.6 280.6 37.7C280.6 36.8 280.3 36.2 279.7 35.6C279.1 35 278.4 34.8 277.6 34.8ZM278.8 39.1C278.5 39.5 278.1 39.6 277.6 39.6C277.1 39.6 276.8 39.4 276.4 39.1C276.1 38.7 275.9 38.3 275.9 37.8C275.9 37.3 276.1 36.8 276.4 36.5C276.7 36.1 277.1 36 277.6 36C278.1 36 278.5 36.2 278.8 36.5C279.1 36.9 279.3 37.3 279.3 37.8C279.3 38.3 279.1 38.8 278.8 39.1Z"
          fill="black"
        />
        <path
          d="M264.8 39.6C264.3 39.6 263.8 39.4 263.4 39.1C263 38.7 262.9 38.3 262.9 37.8C262.9 37.3 263.1 36.8 263.4 36.5C263.8 36.1 264.2 36 264.8 36C265.4 36 265.9 36.2 266.4 36.6L266.8 35.6C266.2 35.1 265.5 34.9 264.8 34.9C264.1 34.9 263.1 35.2 262.5 35.8C261.9 36.4 261.6 37.1 261.6 37.9C261.6 38.7 261.9 39.4 262.5 40C263.1 40.6 263.9 40.9 264.8 40.9C265.7 40.9 266.3 40.7 266.9 40.2L266.5 39.2C265.9 39.6 265.4 39.8 264.8 39.8V39.6Z"
          fill="black"
        />
        <path d="M273.4 35H272.1V40.6H273.4V35Z" fill="black" />
        <path
          d="M258.1 34.8C257.3 34.8 256.6 35.1 256.1 35.6C255.6 36.1 255.3 36.8 255.3 37.7C255.3 38.6 255.6 39.3 256.2 39.8C256.8 40.3 257.6 40.6 258.5 40.6C259.4 40.6 260 40.3 260.6 39.8L260.2 38.9C259.7 39.4 259.1 39.6 258.5 39.6C257.9 39.6 257.4 39.4 257.1 39.1C256.9 38.9 256.7 38.6 256.6 38.3H260.9C260.9 38.1 260.9 37.9 260.9 37.7C260.9 36.9 260.6 36.2 260.1 35.7C259.6 35.2 258.9 34.9 258.1 34.9V34.8ZM256.5 37.3C256.5 36.9 256.7 36.6 256.9 36.3C257.2 36 257.6 35.8 258 35.8C258.4 35.8 258.8 36 259.1 36.2C259.4 36.5 259.5 36.8 259.6 37.2H256.5V37.3Z"
          fill="black"
        />
        <path
          d="M253.3 33.2H252V35H251V36H252V39C252 39.5 252.1 39.9 252.4 40.2C252.7 40.5 253.1 40.6 253.7 40.6H254.5V39.6H254C253.8 39.6 253.6 39.6 253.5 39.5C253.4 39.5 253.3 39.2 253.3 38.9V36.1H254.5V35.1H253.3V33.2Z"
          fill="black"
        />
        <path
          d="M269.8 33.2H268.5V35H267.5V36H268.5V39C268.5 39.5 268.6 39.9 268.9 40.2C269.2 40.5 269.6 40.6 270.2 40.6H271V39.6H270.5C270.3 39.6 270.1 39.6 270 39.5C269.9 39.5 269.8 39.2 269.8 38.9V36.1H271V35.1H269.8V33.2Z"
          fill="black"
        />
        <path
          d="M311.4 34.8C310.6 34.8 309.9 35.1 309.3 35.6C308.7 36.2 308.4 36.9 308.4 37.7C308.4 38.5 308.7 39.2 309.3 39.8C309.9 40.4 310.6 40.6 311.4 40.6C312.2 40.6 312.9 40.3 313.5 39.8C314.1 39.2 314.4 38.6 314.4 37.7C314.4 36.8 314.1 36.2 313.5 35.6C312.9 35 312.2 34.8 311.4 34.8ZM312.6 39.1C312.3 39.5 311.9 39.6 311.4 39.6C310.9 39.6 310.6 39.4 310.2 39.1C309.9 38.7 309.7 38.3 309.7 37.8C309.7 37.3 309.9 36.8 310.2 36.5C310.5 36.1 310.9 36 311.4 36C311.9 36 312.3 36.2 312.6 36.5C312.9 36.9 313.1 37.3 313.1 37.8C313.1 38.3 312.9 38.8 312.6 39.1Z"
          fill="black"
        />
        <path d="M336.5 35H335.2V40.6H336.5V35Z" fill="black" />
        <path
          d="M335.8 32.4C335.6 32.4 335.4 32.4 335.2 32.6C335 32.8 335 33 335 33.2C335 33.4 335 33.6 335.2 33.8C335.4 34 335.6 34 335.8 34C336 34 336.2 34 336.4 33.8C336.6 33.6 336.6 33.5 336.6 33.2C336.6 32.9 336.6 32.8 336.4 32.6C336.2 32.4 336 32.4 335.8 32.4Z"
          fill="black"
        />
        <path
          d="M306.1 37.3C305.9 37.3 305.8 37.3 305.7 37.2C305.4 37.1 305.1 37 304.9 36.9C304.7 36.8 304.6 36.7 304.6 36.5C304.6 36.3 304.6 36.2 304.8 36.1C305 36 305.2 35.9 305.4 35.9C305.8 35.9 306.1 36 306.3 36.2C306.4 36.3 306.6 36.5 306.7 36.6L307.5 35.9C307.4 35.7 307.2 35.5 307 35.3C306.6 35 306.1 34.9 305.5 34.9C304.9 34.9 304.4 35.1 304 35.4C303.6 35.7 303.4 36.1 303.4 36.6C303.4 37.1 303.5 37.2 303.7 37.4C303.9 37.6 304.2 37.8 304.6 38C304.9 38.1 305.2 38.2 305.5 38.3C305.8 38.3 306 38.5 306.2 38.6C306.4 38.7 306.5 38.9 306.5 39C306.5 39.1 306.5 39.3 306.2 39.5C306 39.6 305.8 39.7 305.5 39.7C305.2 39.7 304.9 39.7 304.7 39.5C304.4 39.3 304.2 39.1 304 38.8L303.2 39.6C303.4 39.9 303.7 40.2 304 40.4C304.4 40.7 305 40.8 305.5 40.8C306 40.8 306.7 40.6 307.2 40.3C307.6 40 307.9 39.6 307.9 39.1C307.9 38.6 307.8 38.4 307.5 38.1C307.2 37.8 306.8 37.6 306.3 37.4L306.1 37.3Z"
          fill="black"
        />
        <path
          d="M340.6 34.8C339.8 34.8 339.1 35.1 338.5 35.6C337.9 36.2 337.6 36.9 337.6 37.7C337.6 38.5 337.9 39.2 338.5 39.8C339.1 40.4 339.8 40.6 340.6 40.6C341.4 40.6 342.1 40.3 342.7 39.8C343.3 39.2 343.6 38.6 343.6 37.7C343.6 36.8 343.3 36.2 342.7 35.6C342.1 35 341.4 34.8 340.6 34.8ZM341.8 39.1C341.5 39.5 341.1 39.6 340.6 39.6C340.1 39.6 339.8 39.4 339.4 39.1C339.1 38.7 338.9 38.3 338.9 37.8C338.9 37.3 339.1 36.8 339.4 36.5C339.7 36.1 340.1 36 340.6 36C341.1 36 341.5 36.2 341.8 36.5C342.1 36.9 342.3 37.3 342.3 37.8C342.3 38.3 342.1 38.8 341.8 39.1Z"
          fill="black"
        />
        <path
          d="M332.8 33.2H331.5V35H330.5V36H331.5V39C331.5 39.5 331.6 39.9 331.9 40.2C332.2 40.5 332.6 40.6 333.2 40.6H334V39.6H333.5C333.3 39.6 333.1 39.6 333 39.5C332.9 39.5 332.8 39.2 332.8 38.9V36.1H334V35.1H332.8V33.2Z"
          fill="black"
        />
        <path
          d="M347.4 34.8C347.1 34.8 346.8 34.8 346.5 35C346.2 35.2 346 35.4 345.9 35.6V34.9H344.7V40.5H346V37.7C346 37.2 346 36.9 346.2 36.6C346.3 36.4 346.5 36.2 346.7 36C346.9 35.9 347.1 35.8 347.3 35.8C347.7 35.8 348 35.9 348.2 36.2C348.4 36.5 348.5 36.9 348.5 37.5V40.4H349.8V37.2C349.8 36.5 349.6 35.9 349.2 35.4C348.8 35 348.3 34.7 347.6 34.7L347.4 34.8Z"
          fill="black"
        />
        <path d="M323 35H321.7V40.6H323V35Z" fill="black" />
        <path
          d="M318.3 39.6C317.8 39.6 317.3 39.4 316.9 39.1C316.5 38.7 316.4 38.3 316.4 37.8C316.4 37.3 316.6 36.8 316.9 36.5C317.3 36.1 317.7 36 318.3 36C318.9 36 319.4 36.2 319.9 36.6L320.3 35.6C319.7 35.1 319 34.9 318.3 34.9C317.6 34.9 316.6 35.2 316 35.8C315.4 36.4 315.1 37.1 315.1 37.9C315.1 38.7 315.4 39.4 316 40C316.6 40.6 317.4 40.9 318.3 40.9C319.2 40.9 319.8 40.7 320.4 40.2L320 39.2C319.4 39.6 318.9 39.8 318.3 39.8V39.6Z"
          fill="black"
        />
        <path
          d="M322.3 32.4C322.1 32.4 321.9 32.4 321.7 32.6C321.5 32.8 321.5 33 321.5 33.2C321.5 33.4 321.5 33.6 321.7 33.8C321.9 34 322 34 322.3 34C322.6 34 322.7 34 322.9 33.8C323.1 33.6 323.1 33.5 323.1 33.2C323.1 32.9 323.1 32.8 322.9 32.6C322.7 32.4 322.5 32.4 322.3 32.4Z"
          fill="black"
        />
        <path
          d="M328.8 35.4C328.3 35 327.6 34.9 326.7 34.9C326 34.9 325.3 35 324.8 35.3L325.1 36.3C325.7 36.1 326.2 35.9 326.7 35.9C327.2 35.9 327.6 35.9 327.9 36.2C328.2 36.4 328.3 36.7 328.3 37H327C326.1 37 325.5 37.2 325 37.5C324.5 37.9 324.2 38.3 324.2 38.9C324.2 39.5 324.4 39.8 324.8 40.2C325.2 40.6 325.7 40.7 326.4 40.7C327.1 40.7 327.1 40.7 327.5 40.5C327.8 40.4 328.1 40.2 328.4 40V40.6H329.6V37.2C329.6 36.4 329.4 35.8 328.9 35.5L328.8 35.4ZM328.3 38.9C328.2 39.1 327.9 39.3 327.6 39.5C327.3 39.7 327 39.7 326.6 39.7C326.2 39.7 325.9 39.7 325.7 39.5C325.5 39.3 325.4 39.1 325.4 38.9C325.4 38.7 325.5 38.4 325.8 38.2C326 38 326.5 37.9 327.1 37.9H328.2V38.8L328.3 38.9Z"
          fill="black"
        />
        <path
          d="M228.3 37.3C228.1 37.3 228 37.3 227.9 37.2C227.5 37.1 227.3 37 227.1 36.9C226.9 36.8 226.8 36.7 226.8 36.5C226.8 36.3 226.8 36.2 227 36.1C227.2 36 227.4 35.9 227.6 35.9C228 35.9 228.3 36 228.5 36.2C228.6 36.3 228.8 36.5 228.9 36.6L229.7 35.9C229.6 35.7 229.4 35.5 229.2 35.3C228.8 35 228.3 34.9 227.7 34.9C227.1 34.9 226.6 35.1 226.2 35.4C225.8 35.7 225.6 36.1 225.6 36.6C225.6 37.1 225.7 37.2 225.9 37.4C226.1 37.6 226.4 37.8 226.8 38C227.1 38.1 227.4 38.2 227.7 38.3C228 38.3 228.2 38.5 228.4 38.6C228.6 38.7 228.7 38.9 228.7 39C228.7 39.1 228.7 39.3 228.4 39.5C228.2 39.6 228 39.7 227.7 39.7C227.4 39.7 227.1 39.7 226.9 39.5C226.6 39.3 226.4 39.1 226.2 38.8L225.4 39.6C225.6 39.9 225.9 40.2 226.2 40.4C226.6 40.7 227.2 40.8 227.7 40.8C228.2 40.8 228.9 40.6 229.4 40.3C229.8 40 230.1 39.6 230.1 39.1C230.1 38.6 230 38.4 229.7 38.1C229.4 37.8 229 37.6 228.5 37.4L228.3 37.3Z"
          fill="black"
        />
        <path
          d="M206.3 32.4C206.1 32.4 205.9 32.4 205.7 32.6C205.5 32.8 205.5 33 205.5 33.2C205.5 33.4 205.5 33.6 205.7 33.8C205.9 34 206 34 206.3 34C206.6 34 206.7 34 206.9 33.8C207.1 33.6 207.1 33.5 207.1 33.2C207.1 32.9 207.1 32.8 206.9 32.6C206.7 32.4 206.5 32.4 206.3 32.4Z"
          fill="black"
        />
        <path
          d="M212.6 35.6C212.1 35.1 211.6 34.8 210.9 34.8C210.2 34.8 209.4 35.1 208.9 35.7C208.4 36.3 208.1 37 208.1 37.8C208.1 38.6 208.4 39.3 208.9 39.8C209.4 40.4 210.1 40.7 210.9 40.7C211.7 40.7 212.2 40.4 212.7 39.9V40.2C212.7 40.6 212.5 40.9 212.2 41.3C211.9 41.6 211.4 41.8 210.9 41.8C210.4 41.8 209.7 41.6 209.1 41.2L208.7 42.2C209.3 42.6 210 42.8 210.9 42.8C211.8 42.8 212.6 42.5 213.1 42C213.6 41.5 213.9 40.7 213.9 39.6V35H212.6V35.7V35.6ZM212.6 38.1C212.6 38.5 212.4 38.9 212.1 39.1C211.8 39.4 211.4 39.6 211 39.6C210.6 39.6 210.1 39.4 209.8 39.1C209.5 38.7 209.4 38.3 209.4 37.8C209.4 37.3 209.5 36.8 209.8 36.5C210.1 36.1 210.5 36 211 36C211.5 36 211.8 36.2 212.1 36.5C212.4 36.8 212.5 37.1 212.6 37.5C212.6 37.7 212.6 38 212.6 38.2V38.1Z"
          fill="black"
        />
        <path
          d="M247.5 34.8C246.7 34.8 246 35.1 245.4 35.6C244.8 36.2 244.5 36.9 244.5 37.7C244.5 38.5 244.8 39.2 245.4 39.8C246 40.4 246.7 40.6 247.5 40.6C248.3 40.6 249 40.3 249.6 39.8C250.2 39.2 250.5 38.6 250.5 37.7C250.5 36.8 250.2 36.2 249.6 35.6C249 35 248.3 34.8 247.5 34.8ZM248.7 39.1C248.4 39.5 248 39.6 247.5 39.6C247 39.6 246.7 39.4 246.3 39.1C246 38.7 245.8 38.3 245.8 37.8C245.8 37.3 246 36.8 246.3 36.5C246.6 36.1 247 36 247.5 36C248 36 248.4 36.2 248.7 36.5C249 36.9 249.2 37.3 249.2 37.8C249.2 38.3 249 38.8 248.7 39.1Z"
          fill="black"
        />
        <path
          d="M203.7 36.9C204.2 36.5 204.4 35.9 204.4 35.2C204.4 34.5 204.1 33.9 203.6 33.5C203.1 33.1 202.4 32.8 201.4 32.8H198.9V40.5H200.2V37.5H201L203.1 40.5H204.6L202.4 37.4C202.9 37.4 203.3 37.1 203.6 36.8L203.7 36.9ZM200.3 36.5V33.9H201.2C201.9 33.9 202.3 34 202.6 34.2C202.9 34.4 203 34.7 203 35.2C203 35.7 202.8 36 202.6 36.2C202.3 36.4 201.8 36.5 201.2 36.5H200.3Z"
          fill="black"
        />
        <path d="M206.9 35H205.6V40.6H206.9V35Z" fill="black" />
        <path
          d="M239 33.5C238.5 33 237.8 32.8 236.8 32.8H234.3V40.5H235.6V37.7H236.8C237.7 37.7 238.4 37.5 239 37C239.5 36.5 239.8 35.9 239.8 35.2C239.8 34.5 239.5 33.9 239 33.4V33.5ZM238.1 36.2C237.8 36.4 237.3 36.6 236.7 36.6H235.8V33.8H236.7C237.4 33.8 237.8 33.9 238.1 34.2C238.4 34.4 238.5 34.8 238.5 35.2C238.5 35.6 238.4 36 238.1 36.2Z"
          fill="black"
        />
        <path
          d="M218.2 34.8C217.9 34.8 217.6 34.8 217.3 35C217 35.2 216.8 35.3 216.7 35.6V32.2H215.4V40.5H216.7V37.7C216.7 37.2 216.7 36.9 216.9 36.6C217 36.4 217.2 36.2 217.4 36C217.6 35.9 217.8 35.8 218 35.8C218.4 35.8 218.7 35.9 218.9 36.2C219.1 36.5 219.2 36.9 219.2 37.5V40.4H220.5V37.2C220.5 36.5 220.3 35.9 219.9 35.4C219.5 35 219 34.7 218.3 34.7L218.2 34.8Z"
          fill="black"
        />
        <path
          d="M242.9 35.1C242.6 35.3 242.4 35.5 242.2 35.7V34.9H241V40.5H242.3V37.9C242.3 37.2 242.4 36.8 242.7 36.4C243 36.1 243.4 35.9 243.9 35.9H244V34.8C243.6 34.8 243.2 34.8 242.9 35.1Z"
          fill="black"
        />
        <path
          d="M223.5 33.2H222.2V35H221.2V36H222.2V39C222.2 39.5 222.3 39.9 222.6 40.2C222.9 40.5 223.3 40.6 223.9 40.6H224.7V39.6H224.2C224 39.6 223.8 39.6 223.7 39.5C223.6 39.5 223.5 39.2 223.5 38.9V36.1H224.7V35.1H223.5V33.2Z"
          fill="black"
        />
        <path d="M22.6 11.6H20.3V17.6H34.6V15.6H22.6V11.6Z" fill="black" />
        <path
          d="M5.9 5.6L9.8 15.5L11.7 14.6L5.9 0.1L0 14.7L2 15.6L5.9 5.6Z"
          fill="black"
        />
        <path d="M34.2 0H31.9V11.5H34.2V6.1H36.7V3.9H34.2V0Z" fill="black" />
        <path d="M15.4 0H13.2V17.9H15.4V9.1H18V6.9H15.4V0Z" fill="black" />
        <path d="M29.3 7.8H22V2.5H29.1V0.5H19.8V9.7H29.3V7.8Z" fill="black" />
        <path d="M23 36.3H20.8V42.3H35.5V40.3H23V36.3Z" fill="black" />
        <path
          d="M22.9 34.2C23.5 34.5 24.2 34.6 24.9 34.6C25.6 34.6 26.3 34.5 26.9 34.2C27.5 33.9 28.1 33.6 28.5 33.1C29 32.6 29.3 32.1 29.6 31.5C29.9 30.9 30 30.3 30 29.6C30 28.9 29.9 28.3 29.6 27.7C29.3 27.1 29 26.6 28.5 26.1C28 25.7 27.5 25.3 26.9 25C26.3 24.7 25.6 24.6 24.9 24.6C24.2 24.6 23.5 24.7 22.9 25C22.3 25.3 21.7 25.6 21.3 26.1C20.8 26.6 20.5 27.1 20.2 27.7C19.9 28.3 19.8 28.9 19.8 29.6C19.8 30.3 19.9 30.9 20.2 31.5C20.5 32.1 20.8 32.6 21.3 33.1C21.8 33.5 22.3 33.9 22.9 34.2ZM22.2 28.6C22.4 28.2 22.6 27.9 22.8 27.7C23.1 27.4 23.4 27.2 23.7 27.1C24.1 26.9 24.4 26.9 24.8 26.9C25.2 26.9 25.6 26.9 25.9 27.1C26.3 27.3 26.6 27.5 26.8 27.7C27.1 28 27.3 28.3 27.4 28.6C27.6 29 27.6 29.3 27.6 29.7C27.6 30.1 27.6 30.5 27.4 30.8C27.2 31.2 27 31.5 26.8 31.7C26.5 32 26.2 32.2 25.9 32.3C25.5 32.5 25.2 32.5 24.8 32.5C24.4 32.5 24 32.5 23.7 32.3C23.3 32.1 23 31.9 22.8 31.7C22.5 31.4 22.3 31.1 22.2 30.8C22 30.4 22 30.1 22 29.7C22 29.3 22 28.9 22.2 28.6Z"
          fill="black"
        />
        <path
          d="M14.4 36.4H4.7V34.7H2.5V42.3H16.6V34.7H14.4V36.4ZM14.4 40.5H4.7V38.3H14.4V40.5Z"
          fill="black"
        />
        <path d="M35.3 24.7H33.1V36.2H35.3V24.7Z" fill="black" />
        <path
          d="M14.4 28.4H11.7V30.3H14.4V34.2H16.6V24.7H14.4V28.4Z"
          fill="black"
        />
        <path
          d="M10.9 25.1H8.7V27.4H3.7V25.1H1.5V33.8H10.9V25.1ZM8.7 32H3.7V29.3H8.7V32Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_2567_29911">
          <rect width="350" height="43" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default React.memo(IcLogoNew);
