import React from "react";
import styled from "styled-components";
import { IcLogoNew, IcOutlink } from "../../icons";
import Gnb from "./Gnb";

const Header = () => (
  <StyledWrap>
    <StyledLogo>
      <a href="/">
        <IcLogoNew />
      </a>
    </StyledLogo>
    <Gnb />
    <StyledLink
      href="https://fakenever.com/fakereportboard"
      target="_blank"
      rel="noopener noreferrer"
    >
      FAKE 피해사례
      <IcOutlink />
    </StyledLink>
  </StyledWrap>
);

const StyledWrap = styled.header`
  position: sticky;
  top: 0px;
  max-width: 1920px;
  min-width: 1508px;
  height: 110px;
  margin: 0 auto;
  background: #fff;
  text-align: center;
  z-index: 100;
`;

const StyledLogo = styled.h1`
  position: absolute;
  top: 30px;
  left: 52px;
  z-index: 110;
`;

const StyledLink = styled.a`
  position: absolute;
  top: 25px;
  right: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 174px;
  height: 48px;
  background: #dde1e4;
  font-size: 18px;
  color: #646d74;
  z-index: 101;

  > svg {
    margin-left: 8px;
  }
`;

export default Header;
