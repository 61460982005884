import React from "react";

const IcLogoImage = () => {
  return (
    <svg
      width="115"
      height="120"
      viewBox="0 0 115 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2567_16879)">
        <path
          d="M114.097 22.5445C114.097 22.5445 81.3006 19.2885 57.23 0.576759C33.1594 19.2885 0.363043 22.5445 0.363043 22.5445C-1.27419 65.4636 11.783 99.6865 57.23 119.67C102.677 99.6865 115.734 65.4636 114.097 22.5445ZM95.1746 77.2457C87.4984 91.6666 75.0621 102.869 57.23 111.395C39.3979 102.869 26.9616 91.6666 19.2854 77.2457C12.8215 65.1051 9.58771 46.9292 9.46205 28.916C20.2648 26.8833 39.8857 21.8681 57.23 10.3669C74.5743 21.8718 94.1952 26.8833 104.998 28.916C104.872 46.9292 101.638 65.1014 95.1746 77.2457Z"
          fill="#0076E0"
        />
        <path
          d="M9.46205 28.9197C9.58771 46.9329 12.8215 65.1051 19.2854 77.2494C26.9616 91.6703 39.3979 102.872 57.23 111.398C75.0621 102.872 87.4984 91.6703 95.1746 77.2494C101.638 65.1088 104.872 46.9329 104.998 28.9197C94.1952 26.887 74.5743 21.8718 57.23 10.3706C39.8857 21.8755 20.2648 26.887 9.46205 28.9197ZM87.0291 73.4908C80.8719 85.6979 71.1003 95.4511 57.23 103.205C43.3597 95.4511 33.5881 85.6979 27.4309 73.4908C22.789 64.2883 19.8509 51.4122 18.9343 34.8662C29.3195 32.2126 43.6 27.4007 57.2337 18.9596C70.8674 27.4007 85.1479 32.2126 95.5331 34.8662C94.6756 50.3441 91.6192 64.4029 87.0365 73.4908H87.0291Z"
          fill="#09185F"
        />
        <path
          d="M18.9343 34.8699C19.8509 51.4122 22.789 64.2883 27.4309 73.4945C33.5881 85.7016 43.3597 95.4548 57.23 103.209C71.1003 95.4548 80.8719 85.7016 87.0291 73.4945C91.6118 64.4066 94.6682 50.3515 95.5257 34.8699C85.1405 32.2163 70.86 27.4044 57.2263 18.9633C43.5926 27.4044 29.3121 32.2163 18.9269 34.8699H18.9343Z"
          fill="#09185F"
        />
        <path
          d="M57.23 21.6723C70.1541 29.4556 83.5144 34.0457 93.1012 36.6254C92.1181 51.1128 89.18 64.0887 84.9631 72.4486C79.1755 83.924 70.095 93.1265 57.23 100.544C44.365 93.1265 35.2845 83.924 29.4969 72.4486C25.0878 63.7044 22.3492 51.6598 21.3588 36.6254C30.9456 34.0457 44.3022 29.4556 57.23 21.6723ZM57.23 18.9596C43.5963 27.4007 29.3158 32.2126 18.9306 34.8662C19.8472 51.4085 22.7853 64.2846 27.4272 73.4908C33.5844 85.6979 43.356 95.4511 57.2263 103.205C71.0966 95.4511 80.8682 85.6979 87.0254 73.4908C91.6081 64.4029 94.6645 50.3478 95.522 34.8662C85.1368 32.2126 70.8563 27.4007 57.2226 18.9596H57.23Z"
          fill="#0076E0"
        />
        <path
          d="M37.6424 55.0821C38.8028 54.4538 40.9723 52.4507 40.9723 48.803C40.9723 45.1552 38.278 39.2974 31.4926 39.2974H19.4628V71.3583H31.4926C39.3166 71.3583 42.2103 66.7793 42.2103 62.1743C42.2103 57.5694 39.5124 55.825 37.6461 55.0821H37.6424ZM25.5498 45.1331H30.8163C33.2 45.1331 34.8484 46.8442 34.8484 48.6293C34.8484 50.5622 33.3479 52.1255 31.4963 52.1255H25.5535V45.1331H25.5498ZM32.5126 65.4857H25.5498V58.1348H31.8363C34.2201 58.1348 36.0532 59.7868 36.0532 61.5756C36.0532 63.6748 34.3642 65.4857 32.5126 65.4857Z"
          fill="white"
        />
        <path
          d="M57.3963 39.2974H46.9409V71.3583H53.1683V62.5365H57.3963C63.7309 62.5365 69.038 58.8185 69.038 51.3863C69.038 43.9541 63.8159 39.3011 57.3963 39.3011V39.2974ZM57.3963 56.7009H53.1683V45.1331H57.3963C59.5694 45.1331 62.8254 46.7296 62.8254 51.1091C62.8254 55.4886 59.3477 56.7046 57.3963 56.7046V56.7009Z"
          fill="white"
        />
        <path
          d="M83.1078 38.2331L67.4821 71.3583H74.493L77.7637 64.1035H88.4519L91.7227 71.3583H98.7336L83.1078 38.2331ZM83.1078 57.7098H80.5725L83.1078 52.1255L85.6431 57.7098H83.1078Z"
          fill="white"
        />
        <path
          d="M58.2131 79.1195C58.2131 83.9388 55.2749 87.1726 50.1156 87.1726C55.2786 87.1726 58.2131 90.4064 58.2131 95.2257C58.2131 90.4064 61.1512 87.1726 66.3105 87.1726C61.1475 87.1726 58.2131 83.9388 58.2131 79.1195Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2567_16879">
          <rect
            width="114"
            height="119.096"
            fill="white"
            transform="translate(0.229996 0.576759)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default React.memo(IcLogoImage);
